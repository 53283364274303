import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../../settings';


const Logo = `${settings.IMAGES_BASE_URL}/images/mini/logos/MINI_school_logo-01.svg`;
const BMWPerformanceLogo = `${settings.IMAGES_BASE_URL}/images/mini/logos/mini-jcw-inspired-by-logo.svg`;
const today = new Date();
const showGiftCardBar = 
  today >= new Date(2023,10,24) && 
  today < new Date(2024,0,1);
class Nav extends React.Component {
  state = {
    menuIsOpen: true,
    menuToggle: false,
    isTop: true
  };

  handleMenuToggle = () => {
    this.setState((prev) => {
      return {
        menuIsOpen: !prev.menuIsOpen,
        menuToggle: !prev.menuToggle
      };
    });
  };
  render() {
    return (
      <>
        <header>
          <div className="mobile__nav">
            <div className="container">
              <Link to="/mini">
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>
              </Link>
              <div
                className={`hamburger ${this.state.menuIsOpen ? ' ' : 'is-open'}`}
                onClick={this.handleMenuToggle}
                onKeyDown={this.handleMenuToggle}
                role="button"
                tabIndex={0}
                aria-label="hamburger"
              >
                <div className="hamburger__toggle" />
              </div>
            </div>
          </div>
          <nav className={`nav ${this.state.menuToggle ? ' ' : 'hide-nav'}`}>
            <Link to="/mini">
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>
              </Link>
            <ul className="navbar">
              <li className='width-full mini-nav'>
                <ul>
                  <li className='middle'>
                    <ul>
                      <li>
                        <Link to="/mini/schools" activeClassName="active">
                          Classes
                        </Link>
                      </li>
                      <li>
                        <Link to="/mini/groupevents" activeClassName="active">
                          Group Events
                        </Link>
                      </li>
                      <li>
                        <Link to="/mini/locations" activeClassName="active">
                          Locations
                        </Link>
                      </li>
                      <li>
                        <a className="nav-btn" href="https://pds.eventsbmw.com/" target="_blank" rel="noopener noreferrer">
                          <button>Book Now</button>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="bmw-logo">
                <LazyLoadImage effect="blur" src={BMWPerformanceLogo} alt="BMW Performance Academy Logo" className="bmw-logo" />
              </li>
            </ul>
          </nav>
        </header>
        { showGiftCardBar ?
          <Link to="/mini/giftcards" aria-label="GiftCards" id="mini-gift-card-link">
            <div className="mini-gift-card-bar">
              GIVE THE GIFT OF SPEED - <span style={{color: 'white'}}>&nbsp;GIFT CARDS AVAILABLE</span>
            </div> 
          </Link> 
          : 
          ""
        }
    </>
    );
  }
}
export default Nav;
